import { Image, Header, Grid } from 'semantic-ui-react'
import logo from 'assets/kanzi_logo.svg'

function AppHeader() {
    return (
        <Grid columns='2'>
            <Grid.Column>
                <Image as='a' href='/' size='tiny' src={logo} alt='Kanzi Logo'/>
            </Grid.Column>
            <Grid.Column textAlign='right' verticalAlign='middle'>
                <Header as='span'>Download</Header>
            </Grid.Column>
        </Grid>
    )
}

export default AppHeader
