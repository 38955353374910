import { Button } from 'semantic-ui-react'

interface PropTypes {
    token: string,
    location: string
}

function DownloadLocation(props: PropTypes) {
    const locations = [
        { name: 'ireland', label: 'Ireland / Europe' },
        { name: 'tokyo', label: 'Japan / APAC' }]

    const locationButtons = locations.map(location =>
        <Button key={location.name} as='a' basic={location.name !== props.location}
            href={`/?token=${props.token}&location=${location.name}`}
            data-cy='location-button'>
            {location.label}
        </Button>
    )

    return (
        <div style={{marginBottom: '32px'}}>
            <p style={{marginBottom: '16px'}}>
                Please choose the most optimal download location for you:
            </p>
            <p className='fine-print' style={{marginBottom: '24px'}}>
                ireland / europe is used as default
            </p>
            {locationButtons}
        </div>
    )
}

export default DownloadLocation
