import { Grid, Message } from 'semantic-ui-react'

function IndexView() {
    return (
        <Grid textAlign='center' verticalAlign='middle'
            style={{ height: '100%' }} data-cy='index-view'>
            <Grid.Column textAlign='left' style={{ maxWidth: 500 }}>
                <Message
                    content='Please contact customer support for a download link.'
                    style={{textAlign: 'center'}}>
                </Message>
            </Grid.Column>
        </Grid>
    )
}

export default IndexView
