import './App.css'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import AppHeader from 'components/AppHeader'
import AppFooter from 'components/AppFooter'
import AppView from 'components/AppView'

function App() {
    return (
        <BrowserRouter>
            <div className='app' data-testid='app'>
                <AppHeader />
                <div className='content'>
                    <Routes>
                        <Route path='/' element={<AppView/>}/>
                        <Route path='*' element={<Navigate to='/' replace/>}/>
                    </Routes>
                </div>
                <AppFooter />
            </div>
        </BrowserRouter>
    )
}

export default App
