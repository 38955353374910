import { Button, Table } from 'semantic-ui-react'
import { formatBytes } from 'utils/index'
import api from 'api/index'

export interface File {
    md5hash: string,
    filename: string,
    filesize: number,
    timestamp: string,
    presignedUrl: string
}

interface PropTypes {
    files: Array<File>
}

function FileTable(props: PropTypes) {
    async function downloadFile(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        const href = (e.target as HTMLLinkElement).href
        try {
            // Prevent default download from href.
            e.preventDefault()

            // Audit must be done before the download.
            // Otherwise on Firefox the audit request fails due to page refresh.
            await api.auditDownload(href)
        }
        catch {
        }
        finally {
            // Download the file regardless of audit result.
            window.location.href = href
        }
    }

    const rows = props.files.map(file =>
        <Table.Row active key={file.md5hash} data-testid='file-row'>
            <Table.Cell>&nbsp;&nbsp;{file.filename}</Table.Cell>
            <Table.Cell>{formatBytes(file.filesize)}</Table.Cell>
            <Table.Cell>{file.md5hash}</Table.Cell>
            <Table.Cell>{file.timestamp.substring(0, 10)}</Table.Cell>
            <Table.Cell>
                <Button basic size='mini' color='black' as='a'
                    style={{ marginTop: '3px', marginRight: '3px' }}
                    href={file.presignedUrl}
                    onClick={(e) => downloadFile(e)}
                    data-cy='download-button'>
                    Download
                </Button>
            </Table.Cell>
        </Table.Row>
    )

    const validUntilDate = new Date(new Date().setDate(new Date().getDate() + 1))
    const validUntilDateString = validUntilDate.toISOString().split('T')[0]

    return (
        <div data-cy='file-table'>
            <p className='fine-print' style={{ marginBottom: '24px' }}>
                all following download links are valid until {validUntilDateString}
            </p>
            <Table basic='very' compact>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell className='fine-print'>Filename</Table.HeaderCell>
                        <Table.HeaderCell className='fine-print'>Filesize</Table.HeaderCell>
                        <Table.HeaderCell className='fine-print'>MD5 Checksum</Table.HeaderCell>
                        <Table.HeaderCell className='fine-print'>Created</Table.HeaderCell>
                        <Table.HeaderCell className='fine-print'>Download</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows}
                </Table.Body>
            </Table>
        </div>
    )
}

export default FileTable
