import { useSearchParams } from 'react-router-dom'
import DownloadView from './downloads/DownloadView'
import IndexView from './index/IndexView'

function AppView() {
    const [searchParams] = useSearchParams()
    const token = searchParams.get('token')
    const location = searchParams.get('location') || 'ireland'

    if (token) {
        return <DownloadView token={token} location={location}/>
    }
    else {
        return <IndexView/>
    }
}

export default AppView
