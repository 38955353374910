import { useState, useEffect } from 'react'
import { Message, Loader } from 'semantic-ui-react'
import DownloadLocation from './DownloadLocation'
import FileTable from './FileTable'
import InstallationInstructions from './InstallationInstructions'
import api from 'api/index'

interface PropTypes {
    token: string,
    location: string
}

function DownloadView(props: PropTypes) {
    const [files, setFiles] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        const getFiles = async () => {
            try {
                setIsLoading(true)
                const response = await api.getFiles(props.token, props.location)
                if (response.ok) {
                    const files = await response.json()
                    setFiles(files)
                }
                else {
                    throw new Error(`${response.status} ${response.statusText}`)
                }
            }
            catch (e: any) {
                setErrorMessage(`Failed to fetch files for token
                    '${props.token}': ${e.message}`)
            }
            finally {
                setIsLoading(false)
            }
        }

        getFiles()
    }, [props])

    if (isLoading) {
        return <Loader active={isLoading}>Loading files</Loader>
    }

    if (errorMessage) {
        return <Message error content={errorMessage} />
    }

    if (files.length === 0) {
        const infoMessage = `No files found for token '${props.token}'`
        return <Message content={infoMessage} data-cy='info-message'/>
    }

    return (
        <div>
            <DownloadLocation token={props.token} location={props.location}/>
            <FileTable files={files} />
            <InstallationInstructions token={props.token}/>
        </div>
    )
}

export default DownloadView
