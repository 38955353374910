import { useState, useEffect } from 'react'
import { StatusCodes } from 'http-status-codes'
import api from 'api/index'

interface PropTypes {
    token: string
}

function InstallationInstructions(props: PropTypes) {
    const [instructions, setInstructions] = useState(null)

    useEffect(() => {
        const getInstructions = async () => {
            try {
                const response = await api.getInstallationInstructions(props.token)
                if (response.status === StatusCodes.OK) {
                    const responseData = await response.json()
                    setInstructions(responseData)
                }
            }
            catch (e: any) {
                console.error(`Failed to fetch installation instructions for token
                '${props.token}': ${e.message}`)
            }
        }

        getInstructions()
    }, [props])

    if (!instructions) {
        return <></>
    }

    return (
        <div dangerouslySetInnerHTML={{ __html: instructions }}
            style={{ marginTop: '2em' }} data-cy='installation-instructions' />
    )
}

export default InstallationInstructions
