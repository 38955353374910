const api = {
    /** Get the files available for download for a given token. */
    async getFiles(token: string, location: string) {
        const resource = `/api/files?token=${token}&location=${location}`
        const options = {
            headers: { 'Content-Type': 'application/json' }
        }

        return await fetch(resource, options)
    },
    /** Get the installation instructions associated with a token. */
    async getInstallationInstructions(token: string) {
        const resource = `/api/installation-instructions?token=${token}`
        const options = {
            headers: { 'Content-Type': 'application/json' }
        }
        return await fetch(resource, options)
    },
    /** Audit a download. */
    async auditDownload(url: string) {
        const resource = `/api/audit-download?url=${url}`
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        }

        return await fetch(resource, options)
    }
}

export default api
