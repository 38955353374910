import { Image, Grid } from 'semantic-ui-react'
import logo from 'assets/rightware_logo.svg'

function AppFooter() {
    return (
        <Grid columns='3'>
            <Grid.Column textAlign='left'>
                <span>{`© Rightware ${new Date().getFullYear()}`}</span>
            </Grid.Column>
            <Grid.Column>
                <Image centered size='tiny' src={logo} />
            </Grid.Column>
            <Grid.Column textAlign='right'>
                <span>A ThunderSoft® Company</span>
            </Grid.Column>
        </Grid>
    )
}

export default AppFooter
